import Swiper, {Navigation, Pagination, Scrollbar} from 'swiper';

export default function Sliders() {
   
  new Swiper('.how__slider', {
    slidesPerView: 'auto',
    spaceBetween: 40,
    modules: [ Navigation, Scrollbar ],
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    scrollbar: {
      el: ".swiper-scrollbar",
      hide: false,
    },
    breakpoints: {
      // when window width is >= 640px
      1024: {
        spaceBetween: 15
      },
      320: {

      }
    }
  });
}