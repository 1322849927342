import { clickNamespaceByClass } from "../../utils/clickNamespaceByClass";

export default function Hovers() {
  let setNewAnimationFrameForBtnMore = null;

  window.addEventListener('mouseover', function(e) {
    const customHoverLink = clickNamespaceByClass(e.target, 'cc-custom-hover__link');
    const videoBox = clickNamespaceByClass(e.target, 'video-hover');
    const btnMore = clickNamespaceByClass(e.target, 'btn-more');

    if (customHoverLink) {
      const customHover = customHoverLink.closest('.cc-custom-hover');
      customHover.classList.add('cc-custom-hover--active');
    }

    if(videoBox && !clickNamespaceByClass(e.target, 'like-btn')) {
      videoBox.classList.add('video-hover-enter');
    }

    if(btnMore) {
      const delay = btnMore.dataset.delay || 1000;

      btnMore.classList.add('btn-more--active');
      setNewAnimationFrameForBtnMore = setInterval(() => {
        btnMore.classList.remove('btn-more--active');
        setTimeout(() => btnMore.classList.add('btn-more--active'),0);
      }, delay);
    }
  });
  
  window.addEventListener('mouseout', function(e) {
    const customHoverLink = clickNamespaceByClass(e.target, 'cc-custom-hover__link');
    const videoBox = clickNamespaceByClass(e.target, 'video-hover');
    const btnMore = clickNamespaceByClass(e.target, 'btn-more');

    if (customHoverLink) {
      const customHover = customHoverLink.closest('.cc-custom-hover');
      customHover.classList.remove('cc-custom-hover--active');
    }

    if(videoBox && !clickNamespaceByClass(e.target, 'like-btn')) {
      videoBox.classList.remove('video-hover-enter');
    }

    if(btnMore) {
      btnMore.classList.remove('btn-more--active');
      clearInterval(setNewAnimationFrameForBtnMore);
    }
  });
}