import _ from 'lodash';
import Blazy from 'blazy';

import { endpoints } from '../../constants/endpoints';
import { blazyOption } from '../../main';

const openPopupClass = 'popup--open';
const openBodyClass = 'popup-open';

let openedPopup = '';

export function popup() {
  const closeWrap = document.createElement('div');
  closeWrap.classList.add('popup-close-wrap');
  document.body.append(closeWrap);

  const popups = document.querySelectorAll('.popup');
  _.map(popups, function(e,i) {
    const closeButton = document.createElement('a')
    closeButton.innerHTML = `
      <svg width="20" height="20">
        <use href="#close"/>
      </svg>
    `;
    closeButton.classList.add('popup-close');
    closeButton.classList.add('popup-close-btn');
    closeButton.setAttribute('href', 'javascript:;');
    e.append(closeButton);
  });

  const closeElements = document.querySelectorAll('.popup-close, .popup-close-wrap, .popup-close-btn');
  _.map(closeElements, function(e,i) {
    e.addEventListener('click', closePopup);
  });

  window.addEventListener('click', function(e) {
    openPopup(e);
  });
  
  window.addEventListener('submit', formOpenHandler);
}

export function closePopup(callback) {
  const popup = document.querySelector('.'+openPopupClass);
  const transitionHandler = () => {
    window.dispatchEvent(new CustomEvent("popup/close", {
      bubbles: true,
      detail: {
        popup
      }
    }));
    if (typeof callback === 'function')
      callback();
    popup.removeEventListener('transitionend', transitionHandler);
  }

  document.body.classList.remove(openBodyClass);

  if (openedPopup)
    document.body.classList.remove(openedPopup);
    
  openedPopup = '';
  
  if (popup) {
    popup.classList.remove(openPopupClass);
    popup.addEventListener('transitionend', transitionHandler);
  } else {
    if (typeof callback === 'function')
      callback();
  }

}


export function openPopup(e) {
  const btn = e.target;

  if (btn.closest('a[data-popup]') || btn.hasAttribute('data-popup') && btn.tagName === "A") {
    e.preventDefault();
    closePopup(() => {
      const href  = btn.getAttribute('href') ? btn.getAttribute('href') : btn.closest('[data-popup]').getAttribute('href');
      const popup = document.querySelector(href);

      if (popup) {
        openedPopup = `${openBodyClass}--${popup.getAttribute("id")}`;

        popup.classList.add(openPopupClass);
        document.body.classList.add(openBodyClass);
        document.body.classList.add(openedPopup);
      } else {
        console.warn('popup not found');
      }

      const blazy = new Blazy(blazyOption);
    });
  }
}

function formOpenHandler(e) {
  const self = e.target;
  if(self.hasAttribute('data-popup')) {
    e.preventDefault();

    // validtion this(from PopupStep.js)

    closePopup(() => {
      const popup = document.querySelector(self.getAttribute('data-popup'));

      if (popup)
        popup.classList.add(openPopupClass);
      document.body.classList.add(openBodyClass);

      const blazy = new Blazy(blazyOption);
    });
  }
}