import Blazy from 'blazy';

import svgLoader from './componetns/svgLoader';
import pavelMain from './Pavel/main';
import andreyMain from './Andrey/main';

import { endpoints } from './constants/endpoints';

export const blazyOption = {
  loadInvisible: true,
  breakpoints: [
    { 
      width: endpoints.xs, 
      src: 'data-src-mobile',
      loadInvisible: true
    },
    { 
      width: endpoints.sm, 
      src: 'data-src-table',
      loadInvisible: true
    }
  ]
};

window.onload = async function() {
  const blazy = new Blazy(blazyOption);
  
  await svgLoader();
  
  pavelMain();
  andreyMain();

  document.querySelector('#site').addEventListener('scroll', () => blazy.revalidate());
  window.addEventListener('resize', () => blazy.revalidate());
  document.body.classList.add('loaded');
};