export default function Popup() {
    const button = document.getElementById('hint');
    const close = document.getElementsByClassName("btn--close")[0];
    if (button) {
        button.onclick = () => {
            modal.style.display = "block";
        };
    }
    if (close){
        close.onclick = () => {
            modal.style.display = "none";
        }
    }

    const modal = document.getElementById("modal-hint");

    document.onclick = function (event) {
        if (event.target === modal) {
            modal.style.display = "none";
        }
    }
}