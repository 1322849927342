import Swiper, { Pagination } from 'swiper';
import _ from 'lodash';

export default function Tabs(pss) {
  const ccHomepageTabs = document.querySelectorAll('.cc-homepage-tabs');

  _.map(ccHomepageTabs, (tab, i) => {
    new Swiper(tab, {
      autoHeight: true,
      grabCursor: false,
      simulateTouch: false,
      shortSwipes: false,
      touchMoveStopPropagation: true,
      touchStartForcePreventDefault: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: (index, className) => {
          const title = JSON.parse(tab.getAttribute('data-tabs-title'))[index];
          return '<a href="javascript:;" class="link-title text-small text-inter text-normal cc-homepage-tabs-pagination__item '+className+'">'+title+'</a>';
        }
      },
      modules: [ Pagination ],
      on: {
        slideChangeTransitionEnd: () => _.map(pss, (ps, i) => ps.update())
      }
    });
  });
}