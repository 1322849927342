import Swiper from 'swiper';
import _ from 'lodash';

export default function PopupStep() {
  const sliderOption = {
    speed: 200,
    autoHeight: true,
    grabCursor: false,
    simulateTouch: false,
    shortSwipes: false,
    touchMoveStopPropagation: true,
    touchStartForcePreventDefault: true
  };

  const swipers = new Swiper('.popup-step', sliderOption);
  
  window.addEventListener('submit', function(e) {
    if (e.target.closest('.popup-step')) {
      e.preventDefault();

      // middleware for verify forms
      // if doesn't work throw error
      // you can use noty for throw errors 
      // or something for forms validation
      // ! Popup.js has a validation too
      // His validation with success(in one form)

      if (swipers.length) {
        _.map(swipers, function(swiper, i) {
          if (e.target.closest('.popup-step') === swiper.el) {
            swiper.slideNext();
          }
        });
      } else {
        swipers.slideNext();
      }
    }
  });

  window.addEventListener('popup/close', function(e) {
    if (swipers.length) {
      _.map(swipers, function(swiper, i) {
        if (e.detail.popup.querySelector('.popup-step') === swiper.el) {
          setTimeout(() => swiper.slideTo(0), 100);
        }
      });
    } else {
      if (e.detail.popup.querySelector('.popup-step') === swipers.el) {
        setTimeout(() => swipers.slideTo(0), 100);
      }
    }
  });
};