import PerfectScrollbar from 'perfect-scrollbar';
import _ from 'lodash';
import { endpoints } from '../../constants/endpoints';

export default function CustomScrollbar() {
  let pss = [];

  const csb = document.querySelectorAll('.custom-scrollbar');
  if (csb.length) {
    const updateResize = () => window.innerWidth <= endpoints.sm && pss[0].destroy();

    _.map(csb, function(e,i) {
      const ps = new PerfectScrollbar(e, {
        wheelSpeed: 2,
        wheelPropagation: true,
        minScrollbarLength: 20,
        useBothWheelAxes: true
        });
      const thumbs = e.querySelectorAll('.ps__thumb-y');

      pss.push(ps);

      window.addEventListener('resize', () => {
        ps.update();
        updateResize();
      });
    });

    updateResize();
  }
  
  return pss;
}