import Sliders from "./components/Sliders";
import Popup from "./components/Popup";
import Favorites from "./components/Favorites";


export const smoothScroll = function(elementId) {
    const MIN_PIXELS_PER_STEP = 16;
    const MAX_SCROLL_STEPS = 30;
    let target = document.getElementById(elementId);
    let scrollContainer = target;

    do {
        scrollContainer = scrollContainer.parentNode;
        if (!scrollContainer) return;
        scrollContainer.scrollTop += 1;
        console.log(scrollContainer);
    } while (scrollContainer.scrollTop === 0);

    let targetY = 0;
    do {
        if (target === scrollContainer) break;
        targetY += target.offsetTop;
    } while (target = target.offsetParent);

    let pixelsPerStep = Math.max(MIN_PIXELS_PER_STEP,
        Math.abs(targetY - scrollContainer.scrollTop) / MAX_SCROLL_STEPS);

    let isUp = targetY < scrollContainer.scrollTop;

    const stepFunc = function() {
        if (isUp) {
            scrollContainer.scrollTop = Math.max(targetY, scrollContainer.scrollTop - pixelsPerStep);
            if (scrollContainer.scrollTop <= targetY) {
                return;
            }
        } else {
            scrollContainer.scrollTop = Math.min(targetY, scrollContainer.scrollTop + pixelsPerStep);

            if (scrollContainer.scrollTop >= targetY) {
                return;
            }
        }

        window.requestAnimationFrame(stepFunc);
    };

    window.requestAnimationFrame(stepFunc);
};

export default function andreyMain() {
    console.log('work');
    const button = document.getElementById('hero__scroll-down');
    if(button){
        button.addEventListener('click',(e)=>{
            e.preventDefault();
            smoothScroll('second');
        });
    }
    Sliders();
    Popup();
    Favorites();
}