import Swiper, { Navigation, Pagination, Lazy, Autoplay } from "swiper";

export default function HeroBannerSlider() {
  new Swiper('.hero-banner-slider', {
    lazy: {
      loadPrevNext: true,
    },
    pagination: {
      el: '.swiper-pagination-line',
      type: 'bullets',
      dynamicBullets: false,
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-btn-next',
      prevEl: '.swiper-btn-prev',
    },
    autoplay: {
      delay: 5000,
    },
    loop: true,
    speed: 500,
    modules: [ Navigation, Pagination, Lazy, Autoplay ]
  });
}