import _ from 'lodash';

export default function Tof() {
  const tof = document.querySelector('#table-of-contents-list');
  const titles = document.querySelectorAll('[data-tof]');
  const allLinks = [];
  
  const scrollHandler = (e) => {
    const headerHeight = document.querySelector('.header').offsetHeight;
    const scrollTop = e.target.scrollTop;
    const topTof = tof.offsetTop;
    const tofWrap = tof.closest('.tof-wrap');
    const tofWrapTop = tofWrap.offsetTop;
    const endpoint = tofWrapTop+tofWrap.offsetHeight-tof.offsetHeight;

    _.map(titles, function(title, i) {
      const offsetTop = title.offsetTop;
      const nextOffsetTop = titles[i+1] && titles[i+1].offsetTop;

      if (scrollTop >= offsetTop && nextOffsetTop && scrollTop < nextOffsetTop) {
        _.map(allLinks, link => link.classList.remove('table-of-contents-link--active'));
        allLinks[i].classList.add('table-of-contents-link--active');
        return;
      }

      if (scrollTop >= offsetTop) {
        _.map(allLinks, link => link.classList.remove('table-of-contents-link--active'));
        allLinks[i].classList.add('table-of-contents-link--active');
      }
    });

    if (scrollTop >= endpoint) {
      tof.style.top = (headerHeight) - (scrollTop - endpoint) + 'px';
      return;
    } 

    if (scrollTop+headerHeight-300 > topTof) {
      tof.style.position = 'fixed';
      tof.style.top = headerHeight+20+'px';
      tof.style.width = tofWrap.offsetWidth+'px';
      return;
    } 
    
    tof.style.position = 'static';
    tof.style.top = '0px';
    tof.style.width = 'auto';

  };

  if (tof && titles.length) {
    _.map(titles, function(title, i) {
      const offsetTop = title.offsetTop;
      const link = document.createElement('a');
      
      const clickHandler = (e) => {
        e.preventDefault();

        document.querySelector('#site').scrollTo({
          top: offsetTop,
          behavior: 'smooth'
        })
      };

      link.classList.add('table-of-contents-link');
      link.setAttribute('href', 'javascript:;');
      link.innerHTML = title.dataset.tof;
      link.addEventListener('click', clickHandler);

      if (i === 0) {
        link.classList.add('table-of-contents-link--active');
      }

      tof.append(link);
      allLinks.push(link);
    });
    
    document.querySelector('#site').addEventListener('scroll', scrollHandler);
  }
}