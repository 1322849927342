import { clickNamespaceByClass } from "../../utils/clickNamespaceByClass";
import { hasClass } from "../../utils/hasClass";

export default function Player() {
  window.addEventListener('click', function(e) {
    const player = clickNamespaceByClass(e.target, 'video-player');

    if (player && !hasClass(player, 'video-player--active')) {
      e.preventDefault();

      const video = player.querySelector('.video-player__item');

      player.classList.add('video-player--active');
      video.setAttribute('controls', 'controls');
      video.play();
    }
  });
}