import _ from 'lodash';
import { toggle } from 'slidetoggle';
import { clickNamespaceByClass } from '../../utils/clickNamespaceByClass';


export default function Accordion(mas) {
  
  window.addEventListener('click', function(e) {
    const heading = clickNamespaceByClass(e.target, 'accordion__heading');
    
    if (heading) {
      e.preventDefault();

      let animEnd = false;
      
      const accordion = heading.closest('.accordion');
      const body = accordion.querySelector('.accordion__body');
      const activeClass = 'accordion--active';
      const parents = accordion.closest('.company-masonry'); // add selectors
      const slideOptions = {
        onAnimationStart: () => reqFramFn(),
        onAnimationEnd: () => {animEnd = true; setTimeout(() => update(), 0)},
        onClose: () => body.style.display = "none"
      };

      const reqFramFn = () => {
        if (parents && !animEnd) {
          update();
          this.requestAnimationFrame(reqFramFn);
        }
      }

      const update = () => _.map(mas, (fn) => fn(parents)); // update all parents
      
      accordion.classList.toggle(activeClass);

      if (body.style.display === "none") {
        toggle(body, slideOptions);
      } else {
        setTimeout(() => toggle(body, slideOptions), 200);
      }
    }
  });
}