import Btns from './components/Btns';
import Search from './components/Search';
import { popup } from './components/Popup';
import Fields from './components/Fields';
import PopupStep from './components/PopupStep';
import HeroBannerSlider from './components/HeroBannerSlider';
import CustomScrollbar from './components/CustomScrollbar';
import Tabs from './components/Tabs';
import MasonryCC from './components/Masonry';
import Accordion from './components/Accordion';
import Sliders from './components/Sliders';
import Hovers from './components/Hovers';
import Player from './components/Player';
import Tof from './components/Tof';
import Progressbar from './components/Progressbar';
import LottieComponent from './components/Lottie';


export default function pavelMain() {
  const pss = CustomScrollbar();
  const mas = MasonryCC()
  Btns();
  Search();
  popup();
  Fields();
  PopupStep();
  HeroBannerSlider();
  Tabs(pss);
  Accordion(mas);
  Sliders();
  Hovers();
  Player();
  Tof();
  Progressbar();
  LottieComponent();
}