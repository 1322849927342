import _ from 'lodash';
import { show, hide } from 'slidetoggle';
import Blazy from 'blazy';
import { hasClass } from '../../utils/hasClass';
import { endpoints } from '../../constants/endpoints';
import { blazyOption } from '../../main';

export default function Search() {
  let state = {
    value: '',
    focus: false,
    results: false
  };

  const inputs = document.querySelectorAll('.search-field');

  const setState = (obj) => {
    state = {...state, ...obj};
    init();
  };

  const init = () => {
    _.map(inputs, function(input, index) {
      const bodyResultsClass = 'form-search-results';
      const bodyFocusClass = 'form-search-focus';

      const showOptions = {
        onAnimationStart: () => {},
        onAnimationEnd: () => new Blazy(blazyOption),
        elementDisplayStyle: 'grid',
        transitionFunction: 'ease-in-out'
      };
      const hideOptions = {
        onAnimationStart: () => {},
        onAnimationEnd: () => {},
        miliseconds: 200
      };

      input.value = state.value;

      if (state.results && state.focus) { // output
        document.body.classList.add(bodyResultsClass);
        show('.search-results', showOptions);
      } else { // close
        setTimeout(() => {
          if (!state.results || !state.focus) {
            document.body.classList.remove(bodyResultsClass);
            hide('.search-results', hideOptions);
          }
        }, 100);        
      }

      if (state.focus) {
        document.body.classList.add(bodyFocusClass);
      } else {
        document.body.classList.remove(bodyFocusClass);
      }
    });
  };


  const clickHandler = (e) => {
    if (state.focus) {
      if (
           e.target.closest('.search-results') 
        || hasClass(e.target, 'search-results')
        || hasClass(e.target, 'search-field')
      ) {
        setTimeout(() => setState({focus: true}), 100);
      } else {
        setState({focus: false});
        window.removeEventListener('click', clickHandler);
      }
    }
  };
  const focusHandler = () => {
    window.addEventListener('click', clickHandler);
    setTimeout(() => setState({focus: true}), 100);
  };
  const blurHandler = () => {
    const isMobile = window.innerWidth <= endpoints.sm ? 100 : 170;
    setTimeout(() => setState({focus: false}), isMobile);
  }
  const inputHandler = (e) => setState({
    value: e.target.value, 
    results: e.target.value != ''
  });
  const resizeHandler = () => {
    const ww = window.innerWidth;
    const btn = document.querySelector('[href="#popup-search"]');

    if (ww <= endpoints.sm && !hasClass(btn, 'popup-search-btn--active')) {
      setState({focus: false});
    }
  }

  _.map(inputs, function(input, index) {
    input.addEventListener('blur', blurHandler);
    input.addEventListener('focus', focusHandler);
    input.addEventListener('input', inputHandler);
  });

  window.addEventListener('resize', resizeHandler);

  init();
}