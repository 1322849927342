import _ from 'lodash';
import noUiSlider from 'nouislider';
import { DateRangePicker } from 'vanillajs-datepicker';

import { clickNamespaceByClass } from '../../utils/clickNamespaceByClass';

export default function Fields() {
  const passFields = document.querySelectorAll('.field-password');
  const sliderFields = document.querySelectorAll('.range');
  const daterageFields = document.querySelectorAll('.daterange');
  const selectTabsFields = document.querySelectorAll('.select-tabs');

  _.map(passFields, function(label, i) {
    const btn = label.querySelector('.field-password__link');
    const field = label.querySelector('.field-password__field');
    
    btn.addEventListener('click', function(e) {
      e.preventDefault();
      const type = field.getAttribute('type');
      btn.classList.toggle('field-password__link--active');
      field.setAttribute('type', type === 'password' ? 'text' : 'password');
    });
  });

  window.addEventListener('input', function(e) {
    e.preventDefault();
    if (e.target.tagName === 'SELECT') {
      if (e.target.value != '') {
        e.target.classList.add('select-active');
        e.target.blur();
      } else {
        e.target.classList.remove('select-active');
        e.target.blur();
      }
    }
  });

  window.addEventListener('change', function(e) {
    const checkboxWithAccordion = clickNamespaceByClass(e.target, 'checkbox-accordion');
    const userImegeInput = clickNamespaceByClass(e.target, 'file-field__input');

    if(checkboxWithAccordion) {
      let state = {
        allChecked: 0
      }

      const clickedMainCheckbox = clickNamespaceByClass(e.target, 'checkbox-accordion__main-field');
      const mainCheckbox = checkboxWithAccordion.querySelector('.checkbox-accordion__main-field');
      const elseCheckboxs = checkboxWithAccordion.querySelectorAll('.checkbox__field:not(.checkbox-accordion__main-field)');

      const setState = (obj) => {
        state = {...state, ...obj};
        mainCheckbox.checked = state.allChecked === elseCheckboxs.length;
      }

      if (clickedMainCheckbox) {
        return _.map(elseCheckboxs, function(checkbox, i) {
          checkbox.checked = mainCheckbox.checked
        })
      }

      _.map(elseCheckboxs, function(checkbox, i) {
        if (checkbox.checked)
          return setState({allChecked: state.allChecked+1});
      });
    }

    if (userImegeInput) {
      const parent = userImegeInput.closest('.file-field');
      const img = parent.querySelector('.file-field__img');
            img.setAttribute('src', userImegeInput.value);

      parent.classList.remove('file-field--none')
    }
  });

  if (sliderFields.length)
    _.map(sliderFields, function(range, i) {
      const field = range.querySelector('.range__slide');
      const minField = range.querySelector('.range__min');
      const maxField = range.querySelector('.range__max');

      const min = parseFloat( range.dataset.min || 0 );
      const max = parseFloat( range.dataset.max || 100 );
      const step = parseFloat( range.dataset.step || 1 );
      const start = parseFloat( range.dataset.start || min );
      const end = parseFloat( range.dataset.end || max );
      const prefix = range.dataset.prefix || '';

      const slider = noUiSlider.create(field, {
        range: {
            'min': min,
            'max': max
        },
        step: step,
        start: [start, end],
        connect: true,
        behaviour: 'tap-drag',
        tooltips: false
      });

      const updateSlide = () => {
        slider.set([
          parseFloat(minField.value),
          parseFloat(maxField.value)
        ])
      };

      const blurHandler = (e) => {
        let value = e.target.value
        let parsedValue = parseFloat( value );

        if (value === '' || value === '-')
          return        

        if (parsedValue < min)
          parsedValue = min;

        if (parsedValue > max)
          parsedValue = max;
          
        e.target.value = parsedValue+prefix;
        updateSlide();
      }
      const focusHandler = (e) => e.target.value = parseFloat( e.target.value );

      slider.on('update.one', () => {
        const values = slider.get();
        minField.value = values[0]+prefix;
        maxField.value = values[1]+prefix;
      });

      minField.addEventListener('blur', blurHandler);
      maxField.addEventListener('blur', blurHandler);
      
      minField.addEventListener('focus', focusHandler);
      maxField.addEventListener('focus', focusHandler);
    });

  _.map(daterageFields, function(daterange, i) {
    const getCustomDows = () => {
      return `
        <span class="dow">Sun</span>
        <span class="dow">Mon</span>
        <span class="dow">Tue</span>
        <span class="dow">Wed</span>
        <span class="dow">Thu</span>
        <span class="dow">Fri</span>
        <span class="dow">Sat</span>
      `;
    }

    const dateInputMask = (elm) => {
      elm.addEventListener('keypress', function(e) {
        if(e.keyCode < 47 || e.keyCode > 57) {
          e.preventDefault();
        }
        
        const len = elm.value.length;
        
        // If we're at a particular place, let the user type the slash
        // i.e., 12/12/1212
        if(len !== 1 || len !== 3) {
          if(e.keyCode == 47) {
            e.preventDefault();
          }
        }
        
        // If they don't add the slash, do it for them...
        if(len === 2) {
          elm.value += '.';
        }
    
        // If they don't add the slash, do it for them...
        if(len === 5) {
          elm.value += '.';
        }
      });
    };

    const rangepicker = new DateRangePicker(daterange, {
      nextArrow: `<svg width="20" height="20"><use href="#next"/></svg>`,
      prevArrow: `<svg width="20" height="20"><use href="#prev"/></svg>`,
      format: "mm.dd.yyyy"
    });

    const daysOfWeeks = rangepicker.element.querySelectorAll('.days-of-week');
    const inputs = daterange.querySelectorAll('input');

    _.map(daysOfWeeks, function(daysOfWeek, i) {
      daysOfWeek.innerHTML = getCustomDows();
    });
    
    _.map(inputs, function(input, i) {
      dateInputMask(input);
    });
  });

  window.addEventListener('click', function(e) {
    const userImegeDelete = clickNamespaceByClass(e.target, 'file-field__delete');

    if (userImegeDelete) {
      e.preventDefault();

      const parent = userImegeDelete.closest('.file-field');
      const img = parent.querySelector('.file-field__img');
            img.setAttribute('src', img.dataset.native);

      parent.classList.add('file-field--none')
    }
  });

  _.map(selectTabsFields, function(field, i) {
    const tabsWrap = field.querySelector('.select-tabs__tabs');
    const windowsWrap = field.querySelector('.select-tabs__body');
    const tabs = tabsWrap.querySelectorAll('.select-tabs-item');
    const windows = windowsWrap.querySelectorAll('.select-tabs-wrap');

    tabs[0]?.classList.add('select-tabs-item--active');
    windows[0]?.classList.add('select-tabs-wrap--active');

    _.map(tabs, function(tab, i) {
      tab.addEventListener('click', function(e) {
        e.preventDefault();

        _.map(windows, w=>w.classList.remove('select-tabs-wrap--active'));
        _.map(tabs, t=>t.classList.remove('select-tabs-item--active'));

        tab.classList.add('select-tabs-item--active');
        windows[i].classList.add('select-tabs-wrap--active');
      });
    });
  });
}