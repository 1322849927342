import _ from 'lodash';
import Masonry from 'masonry-layout'

export default function MasonryCC() {
  let allMas = [];

  const masOpt = {
    itemSelector: '.company-masonry__item',
    horizontalOrder: true, 
    transitionDuration: 0,
    gutter: 40,
    resize: true 
  };

  _.map(document.querySelectorAll('.company-masonry'), (e) => { 
    const newMas = new Masonry(e, masOpt);
    allMas.push((elem) => elem === newMas.element && newMas.layout())
  });

  return allMas;
}