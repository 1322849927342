import Lottie from "lottie-web";
import _ from 'lodash';

export default function LottieComponent() {
  const lotties = document.querySelectorAll('.lottie');

  _.map(lotties, function(lottie, i) {
    const opts = {
      container: lottie,
      renderer: 'svg',
      loop: true,
      autoplay: true
    };

    let animObj = Lottie.loadAnimation(opts);

    window.addEventListener('resize', function() {
      animObj.play();
    });
  })

}