import _ from 'lodash';

export default function Progressbar() {
  const pgbrs = document.querySelectorAll('.progressbar');
  
  if (!pgbrs.length)
    return;

  _.map(pgbrs, function(item) {
    const circle = item.querySelector('.progressbar__ring--active');
    const radius = circle.r.baseVal.value;
    const circumference = radius * 2 * Math.PI;
    const currentPercent = item.dataset.percent;

    const setProgress = (percent) => {
      const offset = circumference - percent / 100 * circumference;
      circle.style.strokeDashoffset = offset;
    }

    circle.style.strokeDasharray = `${circumference} ${circumference}`;
    circle.style.strokeDashoffset = circumference;

    setProgress(currentPercent);
  });
}