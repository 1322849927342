import $ from 'jquery'; // ! rewrite on native js
import { hasClass } from '../../utils/hasClass';
import { clickNamespaceByClass } from '../../utils/clickNamespaceByClass';
import { toggle, show, hide } from 'slidetoggle';
import Blazy from 'blazy';

import { endpoints } from '../../constants/endpoints';
import { blazyOption } from '../../main';

const slideOptions = {
  onAnimationStart: () => {},
  onAnimationEnd: () => {}
};

export default function Btns() {
  let popupSearchCloseHandler = () => {};
  let navMenuCloseHandler = () => {};

  const toggleIconBtn = (_self) => {
    const icon = _self.find('.icon-btn__icon');

    icon.toggleClass('active');
    _self.toggleClass('icon-btn--active');
  }

  $('body').on('click', '.icon-btn--click', function(e) {
    const _self = $(e.currentTarget);
    toggleIconBtn(_self);
  });

  $('body').on('click', '[href="#nav-menu"]', function(e) {
    e.preventDefault();

    const _self = $(e.currentTarget);
    const icon = _self.find('.icon-btn__icon');
    const href = _self.attr('href');
    const nextElemNode = document.querySelector(href);
    const currentSlideOpt = {
      ...slideOptions,
      onClose: () => nextElemNode.style.display = "none"
    }

    if (!icon.hasClass('active')) { 
      toggleIconBtn(_self);
      toggle(nextElemNode, currentSlideOpt);

      navMenuCloseHandler = (e) => {
        if (!$(e.target).parents(href).length && !$(e.target).is(href)) {
          _self.removeClass('icon-btn--active');
          icon.removeClass('active');
          toggle(nextElemNode, currentSlideOpt);
          $('body').off('click', navMenuCloseHandler);
        }
      }
  
      $('body').on('click', navMenuCloseHandler);
    } 
  });

  $('body').on('click', '[href="#popup-search"]', function(e) {
    e.preventDefault();

    const _self = $(e.currentTarget);
    const href = _self.attr('href');
    const search = document.querySelector(href);

    if (!_self.hasClass('popup-search-btn--active')) {
      _self.addClass('popup-search-btn--active');
      show(search, slideOptions);

      popupSearchCloseHandler = (e) => {
        if (
             !$(e.target).parents(href).length 
          && !$(e.target).is(href)
          && !hasClass(e.target, 'search-results')
          && !e.target.closest('.search-results')
        ) {
          _self.removeClass('popup-search-btn--active');
          hide(search, slideOptions);
          $('body').off('click', popupSearchCloseHandler);
        }
      }
  
      $('body').on('click', popupSearchCloseHandler);
    }
  });

  $('body').on('click', '.scroll-up', function(e) {
    e.preventDefault();
    document.querySelector('#site').scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  });

  window.addEventListener('click', function(e) {
    const ham = clickNamespaceByClass(e.target, 'ham');
    const likeBtn = clickNamespaceByClass(e.target, 'like-btn');
    const closeBtn = clickNamespaceByClass(e.target, 'btn-close');

    if (closeBtn) {
      e.preventDefault();
      const parrent = document.querySelector(closeBtn.dataset.close);
      hide (parrent, slideOptions);
    }

    if (ham && ham.tagName === "A") {
      e.preventDefault();

      const hamSlideOptions = {
        onAnimationStart: () => {},
        onAnimationEnd: () => new Blazy(blazyOption),
        elementDisplayStyle: 'block',
        transitionFunction: 'linear',
        onClose: () => document.querySelector('.table-dropdown').style.display = "none"
      };

      document.body.classList.toggle('table-dropdown-active');
      ham.classList.toggle('active');
      toggle('.table-dropdown', hamSlideOptions);
    }

    if(likeBtn) {
      likeBtn.classList.toggle('like-btn--liked');
    }
  });
}