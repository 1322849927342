import _ from 'lodash';
import Swiper, { Navigation, Pagination, Lazy, Autoplay } from 'swiper';

export default function Sliders() {

  new Swiper('.cc-inner-page-news-slider', {
    slidesPerView: 1.3,
    spaceBetween: 15
  });

  new Swiper('.cc-homepage-mobile-slider-news', {
    slidesPerView: 1.3,
    spaceBetween: 15
  });
   
  new Swiper('.cc-homepage-mobile-slider-video', {
    slidesPerView: 1.3,
    spaceBetween: 15,
    breakpoints: {
      // when window width is >= 640px
      576: {
        slidesPerView: 2.3
      }
    }
  });

  new Swiper('.cc-inner-page-video-slider', {
    slidesPerView: 1.3,
    spaceBetween: 15,
    breakpoints: {
      // when window width is >= 640px
      576: {
        slidesPerView: 2.3
      }
    }
  });
  
  new Swiper('.hero-slider__wrap', {
    lazy: {
      loadPrevNext: true,
    },
    navigation: {
      nextEl: '.hero-slider__next',
      prevEl: '.hero-slider__prev',
    },
    speed: 500,
    modules: [ Navigation, Lazy ],
    breakpoints: {
      // when window width is >= 640px
      1512: {
        slidesPerGroup: 6,
        slidesPerView: 6,
      },
      1024: {
        slidesPerGroup: 4,
        slidesPerView: 4,
      },
      992: {
        slidesPerGroup: 3,
        slidesPerView: 3,
      },
      480: {
        slidesPerGroup: 2,
        slidesPerView: 2,
      }
    }
  });
}